<template>
    <div class="timeline-events">
        <b-card :class="getCardClass(event)" :bg-variant="['customer_unsubscribed','customer_email_ignored'].includes(event.event) ? 'warning' : ''">
            <div>
                <div>
                    <b-row>
                        <b-col cols="9" class="pl-5" style="position:relative">
                            <div style="position: absolute;left:20px;cursor: pointer">
                                <font-awesome-icon v-if="!isOpened"
                                                   @click="openEvent(event)"
                                                   style="margin-right: 10px"
                                                   icon="chevron-right"
                                />
                                <font-awesome-icon v-else
                                                   @click="closeEvent(event)"
                                                   style="margin-right: 10px"
                                                   icon="chevron-down"
                                />
                            </div>
                            <template v-if="event.event=='customer_todo'">
                                <font-awesome-icon class="mr-1" icon="clock"/>
                                <span class="text-uppercase">{{ event.data.action }} - {{event.data.sub_action}} </span>
                                <a href="#" @click="$root.$children[0].openModal('todo-modal',{id:event.data.id})">
                                    <b :class="event.data.status=='done' ? 'event-done' : ''"
                                       style="">{{ event.data.title }} ({{
                                            event.data.status
                                        }})</b></a>
                            </template>
                            <template v-else-if="event.event=='customer_email_sent'">
                                <font-awesome-icon class="mr-1" icon="envelope"/>
                                <span class="text-uppercase">Email - </span>
                                <a href="#"
                                   @click="$root.$children[0].openModal('show-email-message-modal', {messageId: event.data.sent_email.message_id}, null, {width: '800px'})">
                                    <b style="text-transform: uppercase;">{{ event.data.sent_email.subject }}</b></a>

                                <font-awesome-icon
                                    :class="'ml-2 '+(event.data.sent_email.opened_at ? 'text-success' : 'text-danger') "
                                    icon="circle"></font-awesome-icon>
                            </template>
                            <template v-else-if="event.event=='customer_email_received'">
                                <font-awesome-icon class="mr-1" icon="envelope"/>
                                <span class="text-uppercase">Incoming Email - </span>
                                <b style="text-transform: uppercase;">{{ event.data.incoming_email.subject }}</b>
                            </template>
                            <template v-else-if="event.event=='customer_tags_added'">
                                <font-awesome-icon class="mr-1" icon="calendar"/>
                                Tags added:
                                <b-badge v-for="(tag,index) in event.data"
                                         :key="index"
                                         variant="success"
                                         class="mr-2"
                                >{{ tag }}
                                </b-badge>
                            </template>
                            <template v-else-if="event.event=='customer_tags_deleted'">
                                <font-awesome-icon class="mr-1" icon="calendar"/>
                                Tags deleted:
                                <b-badge v-for="(tag,index) in event.data"
                                         :key="index"
                                         variant="danger"
                                         class="mr-2"
                                >{{ tag }}
                                </b-badge>
                            </template>
                            <template v-else-if="event.event=='offering_created'">
                                <a href="#"
                                   @click.prevent="$root.$children[0].openModal('offering-modal', {id:event.data.id})">
                                    Offering created
                                </a>

                            </template>
                            <template v-else-if="event.data && event.data.is_custom_event">
                                <font-awesome-icon class="mr-1" icon="calendar-plus"/>
                                <b>{{
                                        event.event
                                    }}</b>
                            </template>
                            <template v-else>
                                <font-awesome-icon class="mr-1" icon="calendar"/>
                                <template v-if="event.event=='customer_contract_created'">
                                    <span class="text-uppercase">Contract - </span>
                                </template>
                                <template v-if="event.event=='customer_status_changed'">
                                    <span v-if="event.data.status_id">Changed from <b>{{
                                            getStatus('customer', event.data.status_id.before)
                                        }}</b> to <b>{{ getStatus('customer', event.data.status_id.after) }}</b></span>
                                </template>
                                <template v-if="event.event=='status_changed'">
                                    <span class="text-uppercase">Status - </span>
                                </template>
                                <template v-else>
                                    <span class="text-uppercase"></span>
                                </template>

                                <b>{{
                                        $t('common.event.name.' + event.event).includes('common.event.') ? event.event : $t('common.event.name.' + event.event)
                                    }}</b>
                            </template>

                            by <span>{{ (event.user ? event.user.vorname + ' ' + event.user.name : 'System') }}</span>

                            <font-awesome-icon
                                v-if="event.data"
                                @click="$root.$children[0].openModal('json-modal', {data: event.data})"
                                size="sm"
                                icon="eye"
                                style="cursor:pointer"
                                class="ml-2 text-info"
                            />

                            <template v-if="event.data && event.data.is_custom_event">
                                <font-awesome-icon icon="edit"
                                                   class="ml-2 text-primary"
                                                   size="sm"
                                                   style="cursor:pointer"
                                                   variant="primary"
                                                   @click="$root.$children[0].openModal('event-modal', {id: customerId,event:event}, callback, {width: '800px'})"
                                />

                                <font-awesome-icon icon="trash"
                                                   class="ml-2 text-danger"
                                                   size="sm"
                                                   style="cursor:pointer"
                                                   variant="warning"
                                                   @click="deleteEvent(event.id)"
                                />
                            </template>
                            <template v-if="event.event=='customer_todo'">
                                <font-awesome-icon icon="check"
                                                   class="ml-2 text-success"
                                                   v-if="event.data.status=='done'"
                                                   size="sm"
                                                   variant="success"
                                                   style="cursor:pointer"
                                                   @click="changeTodoStatus(event.data.id,'todo')"
                                />

                                <font-awesome-icon icon="check"
                                                   class="ml-2 text-gray"
                                                   v-if="event.data.status=='todo'"
                                                   size="sm"
                                                   style="cursor:pointer"
                                                   variant="warning"
                                                   @click="changeTodoStatus(event.data.id,'done')"
                                />

                                <font-awesome-icon icon="trash"
                                                   class="ml-2 text-danger"
                                                   size="sm"
                                                   style="cursor:pointer"
                                                   variant="warning"
                                                   @click="deleteTodo(event.data.id)"
                                />
                            </template>

                            <div
                                :class="'event-content '+(!isOpened?'closed':'')+(event.event=='customer_todo' && event.data.status=='done'?' event-done':'')"
                                @click="()=>{!isOpened ? openEvent(event) : closeEvent(event)}"
                            >
                                <div v-if="event.data && event.show_data">
                                    <template v-if="event.event == 'support_status'">
                                        User <b>{{ event.data.user_email }}</b> change status from
                                        <b>{{ event.data.old_status }}</b>
                                        to <b>{{ event.data.new_status }}</b>
                                    </template>
                                    <template v-else-if="event.event == 'support_due_date'">
                                        User <b>{{ event.data.user_email }}</b> change due date from
                                        <b>{{ event.data.old_due_date }}</b>
                                        to <b>{{ event.data.new_due_date }}</b>
                                    </template>
                                    <template v-else-if="event.event == 'support_domain'">
                                        User <b>{{ event.data.user_email }}</b> change domain from
                                        <b>{{ event.data.old_domain }}</b>
                                        to <b>{{ event.data.new_domain }}</b>
                                    </template>
                                    <template v-else-if="event.event == 'support_assign'">
                                        User <b>{{ event.data.user_email }}</b> change assign from
                                        <b>{{ event.data.old_assign }}</b>
                                        to <b>{{ event.data.new_assign }}</b>
                                    </template>
                                    <template v-else-if="event.event == 'support_create'">
                                        User <b>{{ event.data.user_email }}</b> create ticket
                                    </template>
                                    <template v-else-if="event.event == 'support_worklog'">
                                        New work log <b>{{ event.data.worklog_time }}</b> from
                                        <b>{{ event.data.worklog_author }}</b>
                                        to <b>{{ event.data.new_assign }}</b>
                                    </template>
                                    <template v-else-if="event.event == 'support_estimate_time'">
                                        Estimate time <b>{{ event.data.estimate_time }}</b> approve
                                    </template>
                                    <json-viewer v-else :value="event.data"></json-viewer>
                                </div>
                                <template v-else-if="event.event == 'status_changed'">
                                    Changed status from <b>{{ event.data.old_status || '-' }}</b> to
                                    <b>{{ event.data.new_status || '-' }}</b>
                                </template>
                                <template v-else-if="event.event == 'customer_status_changed'">

                                </template>
                                <template v-else-if="event.event == 'payment_changed'">
                                            <span v-if="event.data.status_id">Changed status from <b>{{
                                                    getStatus('payment', event.data.status_id.before)
                                                }}</b> to <b>{{ getStatus('payment', event.data.status_id.after) }}</b></span>
                                </template>
                                <template v-else-if="event.event == 'payment_method_changed'">
                                            <span v-if="event.data.payment_method">Changed payment method from <b>{{
                                                    event.data.payment_method.before || '-'
                                                }}</b> to <b>{{ event.data.payment_method.after || '-' }}</b></span>
                                </template>
                                <template v-else-if="event.event == 'shipping_status_changed'">
                                            <span v-if="event.data.shipping_status">Changed shipping status from <b>{{
                                                    event.data.shipping_status.before || '-'
                                                }}</b> to <b>{{ event.data.shipping_status.after || '-' }}</b></span>
                                </template>
                                <template v-else-if="event.event == 'fulfilment_status_changed'">
                                            <span v-if="event.data.fulfilment_status">Changed Fulfilment status from <b>{{
                                                    event.data.fulfilment_status.before || '-'
                                                }}</b> to <b>{{ event.data.fulfilment_status.after || '-' }}</b></span>
                                </template>
                                <template v-else-if="event.event == 'offering_transaction_assigned'">
                                    <div>Name: <strong>{{ event.data.name }}</strong></div>
                                    <div>Amount: <strong>{{ event.data.amount }}&euro;</strong></div>
                                    <div>Date: {{ event.data.date_time | formatDate('DD.MM.YYYY HH:mm') }}</div>
                                    <div>{{ event.data.description }}</div>
                                </template>
                                <template v-else-if="event.event == 'customer_contract_created'">
                                    <a href="#"
                                       @click="$root.openDocument(event.data.file, 'customer_contracts')">Open</a>
                                </template>
                                <template v-else-if="event.event == 'offering_transaction_un_assigned'">
                                    <div>Name: <strong>{{ event.data.name }}</strong></div>
                                    <div>Amount: <strong>{{ event.data.amount }}&euro;</strong></div>
                                    <div>Date: {{ event.data.date_time | formatDate('DD.MM.YYYY HH:mm') }}</div>
                                    <div>{{ event.data.description }}</div>
                                </template>
                                <template v-else-if="event.event == 'document_created'">
                                    <div>Type: {{ event.data.doc_type }}</div>
                                    <div>Name: <a href="#"
                                                  @click="$root.openDocument(event.data.name)">{{ event.data.name }}</a>
                                    </div>
                                </template>
                                <template v-else-if="event.event == 'customer_email_sent'">
                                    <div>To: {{ event.data.sent_email.recipient_email }}</div>
                                    <div>From: {{ event.data.sent_email.sender_email }}</div>
                                    <div v-html="event.data.sent_email.content"></div>
                                </template>
                                <template v-else-if="event.event == 'customer_email_received'">
                                    <div>From: {{ event.data.incoming_email.sender_name }}
                                        {{ event.data.incoming_email.sender_email }}
                                    </div>
                                    <div>Subject: {{ event.data.incoming_email.subject }}</div>
                                    <div v-html="event.data.incoming_email.body"></div>
                                </template>
                                <template v-else-if="event.event == 'address_changed'">
                                    <div>Type: {{ event.data.type }}</div>
                                    <div>Fields: {{ Object.keys(event.data.changes).join(', ') }}</div>
                                </template>
                                <template v-else-if="event.event == 'customer_todo'">
                                    <div v-if="event.data.description" v-html="event.data.description"></div>
                                </template>
                                <template v-else-if="event.data">
                                    <div v-if="event.data.title">{{ event.data.title }}</div>
                                    <div v-if="event.data.description" v-html="event.data.description"></div>
                                </template>
                                <template v-else>
                                </template>
                            </div>
                        </b-col>
                        <b-col class="text-right" cols="3">
                            <div>{{ event.created_at | formatDate('DD. MMMM YYYY [at] HH:mm') }}</div>
                        </b-col>
                    </b-row>

                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    name: 'zw-event-card',
    props: {
        'event': {
            type: Object,
        },
        'customerId': null,
        'index': null,
        'callback': null,
    },
    data() {
        return {
            isOpened: false,
        }
    },
    mounted() {
        if (this.event.data.description) {
            if (this.event.data.description.startsWith('<p><img')) {
                this.isOpened = true
            } else if ((this.event.data.description.match(/<p>/g) || []).length == 1) {
                this.isOpened = true
            }

        }
    },
    methods: {
        ...mapGetters('CommonData', ['getEntityStatuses']),
        openEvent(event) {
            this.isOpened = true
        },
        closeEvent(event) {
            this.isOpened = false
        },
        getStatus(type, id) {
            let status = this.getEntityStatuses()[type].find(status => status.id == id)
            if (status) {
                return status.name + ' ' + '(' + status.value + ')'
            } else {
                return '-'
            }
        },
        changeTodoStatus(id, newStatus) {
            this.$store.dispatch('Todo/saveTodo', {
                id: id,
                status: newStatus,
            }).then(() => {
                this.callback();
            })
        },
        getCardClass(event) {
            if (event.event == 'customer_todo' && event.data.status == 'todo') {
                if (moment(event.created_at).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')) {
                    return 'card-warning'
                }
            }
        },
        deleteTodo(id) {
            this.$removeConfirm('Crm/deleteTodo', {
                id: id
            }, this.callback)
        },
        deleteEvent(id) {
            this.$removeConfirm('Crm/deleteEvent', {
                id: id
            }, this.callback)
        }
    },
}
</script>